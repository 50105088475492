import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Layout from '../components/layout';
import HelmetEx from '../components/helmet-ex';
import Banner from '../components/banner';
import { graphql } from 'gatsby';
import HtmlRenderer from '../components/html-renderer';
import StringUtils from '../utils/string-utils';
import Footer from '../components/footer';
import Hero from '../components/hero';

const PageTemplate = ({ data, pageContext }) => {
  const { page } = data

  const {
    title,
    body,
    pageMetaData: pageMetadata,
    fullWidth,
    slug,
    showBanner = true,
    heroHeading1,
    heroHeading2,
    heroImage,
    heroDescription,
    showHeroImageShadow
  } = page;

  const hasHeroSection = heroHeading1 || heroHeading2 || heroImage || heroDescription;

  return <Layout>
    <div className="default-page-template">
      <HelmetEx noIndex={pageMetadata?.noIndexing ?? false}>
        <div metadata="title">{pageMetadata?.title}</div>
        <div metadata="keywords">
          {StringUtils.tagsToString(pageMetadata?.keywords)}
        </div>
        <div metadata="path">{`/${slug}`}</div>
        <div metadata="canonicalurl">{`/${slug}`}</div>
        <div metadata="description">
          {pageMetadata?.description1?.description1}
        </div>
        <div metadata="image">
          {pageMetadata?.image?.file?.url}
        </div>
      </HelmetEx>
      {showBanner && <Banner name={title} />}
      {hasHeroSection && <Hero
        description={heroDescription?.heroDescription}
        image={heroImage}
        heading1={heroHeading1}
        heading2={heroHeading2}
        options={{
          showImageShadow: showHeroImageShadow
        }}
      />}
      <div className={classnames({ container: !fullWidth })}>
        <HtmlRenderer
          richContent={body}
          pageContext={pageContext}
        />
      </div>
    </div>
    <Footer />
  </Layout>;
};

export default PageTemplate;

PageTemplate.propTypes = {
  data: PropTypes.object,
  pageContext: PropTypes.object
};

export const query = graphql`
query ($slug: String, $languageRegEx: String) {
  page: contentfulPage(slug: {eq: $slug}, node_locale: {regex: $languageRegEx}) {
    title
    slug
    pageMetaData {
      description
      description1 {
        description1
      }
      title
      keywords
      noIndexing
      image {
        file {
          url
        }
      }
    }
    fullWidth
    showBanner
    heroHeading1
    heroHeading2
    heroDescription {
      heroDescription
    }
    heroImage {
      gatsbyImageData
      file {
        url
      }
    }
    showHeroImageShadow
    body {
      raw
      references {
        ... on Node {
          __typename
          ... on ContentfulOpenRolesFeed {
            contentful_id
            title
            showTitle
            category
          }
          ... on ContentfulContactUs {
            contentful_id
            type
          }
          ... on ContentfulSocialLinkButtonsGroup {
            contentful_id
            socialLinkButtons {
              name
              url
              icon {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
          ... on ContentfulSocialShareButtonsGroup {
            contentful_id
            buttons
            borderedIcon
            url
            horizontalAlignment
          }
          ... on ContentfulImageContentRow {
            title
            imageAlignment
            learnMoreActionPath
            contentful_id
            description {
              raw
              references {
                ... on Node {
                  __typename
                  ... on ContentfulGrid {
                    contentful_id
                    numberOfColumns
                    numberOfColumnsOnMobile
                    stackOnMobile
                    horizontalAlignment
                    verticalAlignment
                    gutter
                    columns {
                      ... on ContentfulCard {
                        contentful_id
                        title
                        imagePosition
                        imageSize
                        contentAlignment
                        cardStyle
                        backgroundColor
                        link
                        linkText
                        textColor
                        padding
                        image {
                          gatsbyImageData(layout: FULL_WIDTH)
                          file {
                            fileName
                            url
                          }
                        }
                        body {
                          raw
                          references {
                            __typename
                            ... on Node {
                              ... on ContentfulAsset {
                                contentful_id
                                gatsbyImageData(layout: FULL_WIDTH)
                              }
                              ... on ContentfulCountDownTimer {
                                contentful_id
                                date
                                placeholder
                                endMessage
                              }
                              ... on ContentfulNewsletter {
                                contentful_id
                                caption
                                name
                                title
                                showUntil
                              }
                              ... on ContentfulLinkButton {
                                contentful_id
                                title
                                newTab
                                endDate
                                startDate
                                color
                                url
                              }
                            }
                          }
                        }
                        internal {
                          type
                        }
                      }
                      ... on ContentfulStyledContent {
                        id
                        contentful_id
                        fontSize
                        fontColor
                        content {
                          raw
                          references {
                            __typename
                            ... on Node {
                              ... on ContentfulAsset {
                                contentful_id
                                gatsbyImageData(layout: FULL_WIDTH)
                              }
                              ... on ContentfulSuperscript {
                                contentful_id
                                text
                              }
                            }
                          }
                        }
                        indentation
                        internal {
                          type
                        }
                      }
                      ... on ContentfulTestimonial {
                        contentful_id
                        __typename
                        name
                        title
                        profilePicture {
                          gatsbyImageData(layout: FULL_WIDTH)
                        }
                        quote {
                          quote
                        }
                        body {
                          raw
                        }
                        shadowBorders
                        featured
                      }
                    }
                  }
                }
              }
            }
            titleSize
            image {
              gatsbyImageData(layout: FULL_WIDTH)
              file {
                fileName
                url
              }
            }
          }
          ... on ContentfulAsset {
            contentful_id
            gatsbyImageData(layout: FULL_WIDTH)
          }
          ... on ContentfulCountDownTimer {
            contentful_id
            date
            placeholder
            endMessage
          }
          ... on ContentfulTestimonialComponent {
            contentful_id
            testimonials {
              name
              title
              profilePicture {
                gatsbyImageData(layout: FULL_WIDTH)
              }
              quote {
                quote
              }
              body {
                raw
              }
              shadowBorders
              featured
            }
            title
            subtitle {
              subtitle
            }
            filter
          }
          ... on ContentfulSection {
            contentful_id
            navigationId
            callToAction
            callToActionLabel
            xPaddings
            yPaddings
            title
            titleStyle
            description {
              description
            }
            backgroundColor
            backgroundImage {
              file {
                url
              }
            }
            backgroundImageSize
            content {
              raw
              references {
                ... on Node {
                  __typename
                  ... on ContentfulOpenRolesFeed {
                    contentful_id
                    title
                    showTitle
                    category
                  }
                  ... on ContentfulContactUs {
                    contentful_id
                    type
                  }
                  ... on ContentfulSocialLinkButtonsGroup {
                    contentful_id
                    socialLinkButtons {
                      name
                      url
                      icon {
                        gatsbyImageData
                      }
                    }
                  }
                  ... on ContentfulSocialShareButtonsGroup {
                    contentful_id
                    buttons
                    borderedIcon
                    url
                  }
                  ... on ContentfulImageContentRow {
                    title
                    imageAlignment
                    learnMoreActionPath
                    contentful_id
                    description {
                      raw
                      references {
                        ... on Node {
                          __typename
                          ... on ContentfulGrid {
                            contentful_id
                            numberOfColumns
                            numberOfColumnsOnMobile
                            stackOnMobile
                            horizontalAlignment
                            verticalAlignment
                            gutter
                            columns {
                              ... on ContentfulCard {
                                contentful_id
                                title
                                imagePosition
                                imageSize
                                contentAlignment
                                cardStyle
                                backgroundColor
                                link
                                linkText
                                textColor
                                padding
                                image {
                                  gatsbyImageData(layout: FULL_WIDTH)
                                  file {
                                    fileName
                                    url
                                  }
                                }
                                body {
                                  raw
                                  references {
                                    __typename
                                    ... on Node {
                                      ... on ContentfulAsset {
                                        contentful_id
                                        gatsbyImageData(layout: FULL_WIDTH)
                                      }
                                      ... on ContentfulCountDownTimer {
                                        contentful_id
                                        date
                                        placeholder
                                        endMessage
                                      }
                                      ... on ContentfulNewsletter {
                                        contentful_id
                                        caption
                                        name
                                        title
                                        showUntil
                                      }
                                      ... on ContentfulLinkButton {
                                        contentful_id
                                        title
                                        newTab
                                        endDate
                                        startDate
                                        color
                                        url
                                      }
                                    }
                                  }
                                }
                                internal {
                                  type
                                }
                              }
                              ... on ContentfulStyledContent {
                                id
                                contentful_id
                                fontSize
                                fontColor
                                content {
                                  raw
                                  references {
                                    __typename
                                    ... on Node {
                                      ... on ContentfulAsset {
                                        contentful_id
                                        gatsbyImageData(layout: FULL_WIDTH)
                                      }
                                      ... on ContentfulSuperscript {
                                        contentful_id
                                        text
                                      }
                                    }
                                  }
                                }
                                indentation
                                internal {
                                  type
                                }
                              }
                              ... on ContentfulTestimonial {
                                contentful_id
                                __typename
                                name
                                title
                                profilePicture {
                                  gatsbyImageData(layout: FULL_WIDTH)
                                }
                                quote {
                                  quote
                                }
                                body {
                                  raw
                                }
                                shadowBorders
                                featured
                              }
                              ... on ContentfulResource {
                                contentful_id
                                __typename
                                key
                                value {
                                  value
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                    titleSize
                    image {
                      gatsbyImageData(layout: FULL_WIDTH)
                      file {
                        fileName
                        url
                      }
                    }
                  }
                  ... on ContentfulAsset {
                    contentful_id
                    gatsbyImageData(layout: FULL_WIDTH)
                  }
                  ... on ContentfulCountDownTimer {
                    contentful_id
                    date
                    placeholder
                    endMessage
                  }
                  ... on ContentfulTestimonialComponent {
                    contentful_id
                    testimonials {
                      name
                      title
                      profilePicture {
                        gatsbyImageData(layout: FULL_WIDTH)
                      }
                      quote {
                        quote
                      }
                      body {
                        raw
                      }
                      shadowBorders
                      featured
                    }
                    title
                    subtitle {
                      subtitle
                    }
                    filter
                  }
                  ... on ContentfulCard {
                    contentful_id
                    title
                    imagePosition
                    imageSize
                    contentAlignment
                    cardStyle
                    backgroundColor
                    link
                    linkText
                    textColor
                    padding
                    image {
                      gatsbyImageData(layout: FULL_WIDTH)
                      file {
                        fileName
                        url
                      }
                    }
                    body {
                      raw
                      references {
                        __typename
                        ... on Node {
                          ... on ContentfulAsset {
                            contentful_id
                            gatsbyImageData(layout: FULL_WIDTH)
                          }
                          ... on ContentfulCountDownTimer {
                            contentful_id
                            date
                            placeholder
                            endMessage
                          }
                          ... on ContentfulNewsletter {
                            contentful_id
                            caption
                            name
                            title
                            showUntil
                          }
                          ... on ContentfulLinkButton {
                            contentful_id
                            title
                            newTab
                            endDate
                            startDate
                            color
                            url
                          }
                          ... on ContentfulAccordion {
                            contentful_id
                            id
                            accordionItems {
                              title
                              id
                              icon {
                                gatsbyImageData(layout: FULL_WIDTH)
                                file {
                                  fileName
                                  url
                                }
                              }
                              content {
                                content
                              }
                            }
                          }
                          ... on ContentfulGrid {
                            contentful_id
                            numberOfColumns
                            numberOfColumnsOnMobile
                            stackOnMobile
                            horizontalAlignment
                            verticalAlignment
                            gutter
                            columns {
                              ... on ContentfulCard {
                                contentful_id
                                title
                                imagePosition
                                imageSize
                                contentAlignment
                                cardStyle
                                backgroundColor
                                link
                                linkText
                                textColor
                                padding
                                image {
                                  gatsbyImageData(layout: FULL_WIDTH)
                                  file {
                                    fileName
                                    url
                                  }
                                }
                                body {
                                  raw
                                  references {
                                    __typename
                                    ... on Node {
                                      ... on ContentfulAsset {
                                        contentful_id
                                        gatsbyImageData(layout: FULL_WIDTH)
                                      }
                                      ... on ContentfulCountDownTimer {
                                        contentful_id
                                        date
                                        placeholder
                                        endMessage
                                      }
                                      ... on ContentfulNewsletter {
                                        contentful_id
                                        caption
                                        name
                                        title
                                        showUntil
                                      }
                                      ... on ContentfulLinkButton {
                                        contentful_id
                                        title
                                        newTab
                                        endDate
                                        startDate
                                        color
                                        url
                                      }
                                    }
                                  }
                                }
                                internal {
                                  type
                                }
                              }
                              ... on ContentfulStyledContent {
                                id
                                contentful_id
                                fontSize
                                fontColor
                                content {
                                  raw
                                  references {
                                    __typename
                                    ... on Node {
                                      ... on ContentfulAsset {
                                        contentful_id
                                        gatsbyImageData(layout: FULL_WIDTH)
                                      }
                                      ... on ContentfulSuperscript {
                                        contentful_id
                                        text
                                      }
                                    }
                                  }
                                }
                                indentation
                                internal {
                                  type
                                }
                              }
                              ... on ContentfulTestimonial {
                                contentful_id
                                __typename
                                name
                                title
                                profilePicture {
                                  gatsbyImageData(layout: FULL_WIDTH)
                                }
                                quote {
                                  quote
                                }
                                body {
                                  raw
                                }
                                shadowBorders
                                featured
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                  ... on ContentfulAccordion {
                    contentful_id
                    id
                    accordionItems {
                      title
                      id
                      icon {
                        gatsbyImageData(layout: FULL_WIDTH)
                        file {
                          fileName
                          url
                        }
                      }
                      content {
                        content
                      }
                    }
                  }
                  ... on ContentfulStyledContent {
                    contentful_id
                    indentation
                    fontColor
                    fontSize
                    content {
                      raw
                      references {
                        __typename
                        ... on Node {
                          ... on ContentfulAsset {
                            contentful_id
                            gatsbyImageData(layout: FULL_WIDTH)
                          }
                          ... on ContentfulContactUs {
                            contentful_id
                            buttonText
                            buttonColor
                            type
                          }
                          ... on ContentfulSuperscript {
                            contentful_id
                            text
                          }
                        }
                      }
                    }
                  }
                  ... on ContentfulGrid {
                    contentful_id
                    numberOfColumns
                    numberOfColumnsOnMobile
                    stackOnMobile
                    horizontalAlignment
                    verticalAlignment
                    gutter
                    columns {
                      ... on ContentfulCard {
                        contentful_id
                        title
                        imagePosition
                        imageSize
                        contentAlignment
                        cardStyle
                        backgroundColor
                        link
                        linkText
                        textColor
                        padding
                        image {
                          gatsbyImageData(layout: FULL_WIDTH)
                          file {
                            fileName
                            url
                          }
                        }
                        body {
                          raw
                          references {
                            __typename
                            ... on Node {
                              ... on ContentfulAsset {
                                contentful_id
                                gatsbyImageData(layout: FULL_WIDTH)
                              }
                              ... on ContentfulCountDownTimer {
                                contentful_id
                                date
                                placeholder
                                endMessage
                              }
                              ... on ContentfulNewsletter {
                                contentful_id
                                caption
                                name
                                title
                                showUntil
                              }
                              ... on ContentfulLinkButton {
                                contentful_id
                                title
                                newTab
                                endDate
                                startDate
                                color
                                url
                              }
                            }
                          }
                        }
                        internal {
                          type
                        }
                      }
                      ... on ContentfulStyledContent {
                        id
                        contentful_id
                        fontSize
                        fontColor
                        content {
                          raw
                          references {
                            __typename
                            ... on Node {
                              ... on ContentfulAsset {
                                contentful_id
                                gatsbyImageData(layout: FULL_WIDTH)
                              }
                              ... on ContentfulContactUs {
                                contentful_id
                                buttonText
                                buttonColor
                                type
                              }
                              ... on ContentfulSuperscript {
                                contentful_id
                                text
                              }
                            }
                          }
                        }
                        indentation
                        internal {
                          type
                        }
                      }
                      ... on ContentfulTestimonial {
                        contentful_id
                        __typename
                        name
                        title
                        profilePicture {
                          gatsbyImageData(layout: FULL_WIDTH)
                        }
                        quote {
                          quote
                        }
                        body {
                          raw
                        }
                        shadowBorders
                        featured
                      }
                      ... on ContentfulResource {
                        contentful_id
                        __typename
                        key
                        value {
                          value
                        }
                      }
                    }
                  }
                  ... on ContentfulBrandsSlider {
                    contentful_id
                    title
                    brands {
                      description
                      gatsbyImageData(width: 400, layout: CONSTRAINED)
                      title
                    }
                  }
                  ... on ContentfulSuperscript {
                    contentful_id
                    text
                  }
                  ... on ContentfulResourceSet {
                    contentful_id
                    name
                    resources {
                      key
                      value {
                        value
                      }
                    }
                  }
                  ... on ContentfulNewsletter {
                    contentful_id
                    caption
                    name
                    title
                    showUntil
                  }
                  ... on ContentfulLinkButton {
                    contentful_id
                    title
                    newTab
                    endDate
                    startDate
                    color
                    url
                  }
                }
                ... on ContentfulTestimonial {
                  contentful_id
                  name
                  title
                  profilePicture {
                    gatsbyImageData(layout: FULL_WIDTH)
                  }
                  quote {
                    quote
                  }
                  body {
                    raw
                  }
                  shadowBorders
                  featured
                }
              }
            }
          }
          ... on ContentfulCard {
            contentful_id
            title
            imagePosition
            imageSize
            contentAlignment
            cardStyle
            backgroundColor
            link
            linkText
            textColor
            padding
            image {
              gatsbyImageData(layout: FULL_WIDTH)
              file {
                fileName
                url
              }
            }
            body {
              raw
              references {
                __typename
                ... on Node {
                  ... on ContentfulAsset {
                    contentful_id
                    gatsbyImageData(layout: FULL_WIDTH)
                  }
                  ... on ContentfulCountDownTimer {
                    contentful_id
                    date
                    placeholder
                    endMessage
                  }
                  ... on ContentfulNewsletter {
                    contentful_id
                    caption
                    name
                    title
                    showUntil
                  }
                  ... on ContentfulLinkButton {
                    contentful_id
                    title
                    newTab
                    endDate
                    startDate
                    color
                    url
                  }
                  ... on ContentfulAccordion {
                    contentful_id
                    id
                    accordionItems {
                      title
                      id
                      icon {
                        gatsbyImageData(layout: FULL_WIDTH)
                        file {
                          fileName
                          url
                        }
                      }
                      content {
                        content
                      }
                    }
                  }
                  ... on ContentfulGrid {
                    contentful_id
                    numberOfColumns
                    numberOfColumnsOnMobile
                    stackOnMobile
                    horizontalAlignment
                    verticalAlignment
                    gutter
                    columns {
                      ... on ContentfulCard {
                        contentful_id
                        title
                        imagePosition
                        imageSize
                        contentAlignment
                        cardStyle
                        backgroundColor
                        link
                        linkText
                        textColor
                        padding
                        image {
                          gatsbyImageData(layout: FULL_WIDTH)
                          file {
                            fileName
                            url
                          }
                        }
                        body {
                          raw
                          references {
                            __typename
                            ... on Node {
                              ... on ContentfulAsset {
                                contentful_id
                                gatsbyImageData(layout: FULL_WIDTH)
                              }
                              ... on ContentfulCountDownTimer {
                                contentful_id
                                date
                                placeholder
                                endMessage
                              }
                              ... on ContentfulNewsletter {
                                contentful_id
                                caption
                                name
                                title
                                showUntil
                              }
                              ... on ContentfulLinkButton {
                                contentful_id
                                title
                                newTab
                                endDate
                                startDate
                                color
                                url
                              }
                            }
                          }
                        }
                        internal {
                          type
                        }
                      }
                      ... on ContentfulStyledContent {
                        id
                        contentful_id
                        fontSize
                        fontColor
                        content {
                          raw
                          references {
                            __typename
                            ... on Node {
                              ... on ContentfulAsset {
                                contentful_id
                                gatsbyImageData(layout: FULL_WIDTH)
                              }
                              ... on ContentfulSuperscript {
                                contentful_id
                                text
                              }
                            }
                          }
                        }
                        indentation
                        internal {
                          type
                        }
                      }
                      ... on ContentfulTestimonial {
                        contentful_id
                        __typename
                        name
                        title
                        profilePicture {
                          gatsbyImageData(layout: FULL_WIDTH)
                        }
                        quote {
                          quote
                        }
                        body {
                          raw
                        }
                        shadowBorders
                        featured
                      }
                    }
                  }
                }
              }
            }
          }
          ... on ContentfulAccordion {
            contentful_id
            id
            accordionItems {
              title
              id
              icon {
                gatsbyImageData
                file {
                  fileName
                  url
                }
              }
              content {
                content
              }
            }
          }
          ... on ContentfulStyledContent {
            contentful_id
            indentation
            fontColor
            fontSize
            content {
              raw
              references {
                __typename
                ... on Node {
                  ... on ContentfulAsset {
                    contentful_id
                    gatsbyImageData(layout: FULL_WIDTH)
                  }
                  ... on ContentfulSuperscript {
                    contentful_id
                    text
                  }
                }
              }
            }
          }
          ... on ContentfulGrid {
            contentful_id
            numberOfColumns
            numberOfColumnsOnMobile
            stackOnMobile
            horizontalAlignment
            verticalAlignment
            gutter
            columns {
              ... on ContentfulCard {
                contentful_id
                title
                imagePosition
                imageSize
                contentAlignment
                cardStyle
                backgroundColor
                link
                linkText
                textColor
                padding
                image {
                  gatsbyImageData(layout: FULL_WIDTH)
                  file {
                    fileName
                    url
                  }
                }
                body {
                  raw
                  references {
                    __typename
                    ... on Node {
                      ... on ContentfulAsset {
                        contentful_id
                        gatsbyImageData(layout: FULL_WIDTH)
                      }
                      ... on ContentfulCountDownTimer {
                        contentful_id
                        date
                        placeholder
                        endMessage
                      }
                      ... on ContentfulNewsletter {
                        contentful_id
                        caption
                        name
                        title
                        showUntil
                      }
                      ... on ContentfulLinkButton {
                        contentful_id
                        title
                        newTab
                        endDate
                        startDate
                        color
                        url
                      }
                    }
                  }
                }
                internal {
                  type
                }
              }
              ... on ContentfulStyledContent {
                id
                contentful_id
                fontSize
                fontColor
                content {
                  raw
                  references {
                    __typename
                    ... on Node {
                      ... on ContentfulAsset {
                        contentful_id
                        gatsbyImageData(layout: FULL_WIDTH)
                      }
                      ... on ContentfulSuperscript {
                        contentful_id
                        text
                      }
                    }
                  }
                }
                indentation
                internal {
                  type
                }
              }
            }
          }
          ... on ContentfulBrandsSlider {
            contentful_id
            title
            brands {
              description
              gatsbyImageData(width: 400, layout: CONSTRAINED)
              title
            }
          }
          ... on ContentfulTypedHeaderComponent {
            contentful_id
            preWord
            socials {
              facebookUrl
              instagramUrl
              linkedInUrl
              twitterUrl
            }
            words
            learnMoreButtonText
            learnMorePath
          }
          ... on ContentfulHeroSection {
            contentful_id
            title
            description {
              description
            }
          }
          ... on ContentfulTabs {
            contentful_id
            activeTab
            tabs {
              path
              text
            }
          }
          ... on ContentfulSuperscript {
            contentful_id
            text
          }
          ... on ContentfulResourceSet {
            contentful_id
            name
            resources {
              key
              value {
                value
              }
            }
          }
          ... on ContentfulNewsletter {
            contentful_id
            caption
            name
            title
            showUntil
          }
          ... on ContentfulLinkButton {
            contentful_id
            title
            newTab
            endDate
            startDate
            color
            url
          }
          ... on ContentfulTestimonial {
            contentful_id
            name
            title
            profilePicture {
              gatsbyImageData(layout: FULL_WIDTH)
            }
            quote {
              quote
            }
            body {
              raw
            }
            shadowBorders
            featured
          }
          ... on ContentfulNestedSection {
            contentful_id
            link
            linkText
            title
            column2 {
              raw
            }
            column1 {
              raw
            }
          }
        }
      }
    }
  }
}
`;
